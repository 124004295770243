import axios from "axios"
import store from '@/store/index';
import router from '@/router/index';
import { GET_TOKEN } from "./token";
let requestFailureCount =0
function createAxiosInstance(baseURL){
    let request=axios.create({
        baseURL: baseURL,
        timeout:12000
    })
    request.interceptors.request.use((config)=>{
        if(store.getters.getToken){
            config.headers.Authorization='Bearer '+store.getters.getToken
        }
        config.headers["Content-Type"]= 'application/json';
        return config
    })
    
    request.interceptors.response.use((response)=>{
        // if(response.data.Status==-100){
        //     router.push('/login')
        // }
        return response.data;
        
    },(error)=>{
        let message='';
        //http状态码
        let status =error.request.status;
        switch(status){
            case 401:
                message="TOKEN过期"
                break;
            case 403:
                message="无权访问"
                break;
            case 404:
                message="请求地址错误"
                break;
            case 500:
                message="服务器出现问题"
                break;
            default:
                message="网络出现问题"
                break;
        }
        // this.$message('error',message);
        if(baseURL=='https://fly-api.heteen.com'){
            if (error.response) {
                // 请求已发出，但服务器响应的状态码不在 2xx 范围内
                 return error.response?.data
              } else if (error.request) {
                // 请求已发出，但没有收到响应
                requestFailureCount++;
                if(requestFailureCount==1){
                    let err={
                        message:"网络不通畅，请稍后重试"
                    }
                    return err
                }
           
                if(requestFailureCount>10){
                    requestFailureCount=0;
                }
              } else {
                // 发送请求时出了点问题
                let error={
                    message:error.message || '网络出现问题'
                }
                return error
              }
        }else{
            return Promise.reject(error.response?.data);
        }
    })
    return request
}

const request = createAxiosInstance("https://fly-api.cnanydoor.com");
const requestwrj = createAxiosInstance("https://wrjapi.heteen.com");
const requestfly = createAxiosInstance("https://fly-api.heteen.com");
export {request,requestwrj,requestfly};